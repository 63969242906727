import React from 'react'


function App() {

	const titleOne = import.meta.env.VITE_TITLE_ONE
	const titleTwo = import.meta.env.VITE_TITLE_TWO

	const norebaseLogo = import.meta.env.VITE_NOREBASE_LOGO
	const norebasePartnerLogo = import.meta.env.VITE_NOREBASE_PARTNER_LOGO
	const norebasePartner = import.meta.env.VITE_NOREBASE_PARTNER
	const image = import.meta.env.VITE_IMAGE
	
	const buttonColors = {
		smartcash: 'bg-[#FC8600]',
		ecobank: 'bg-[#00536d]'
	}
	const backgroundColors = {
		smartcash: 'bg-[#fff7ed]',
		ecobank: 'bg-[#ecfeff]'
	}

	const buttonColor = buttonColors[(norebasePartner).toLowerCase() as keyof typeof buttonColors]
	const backgroundColor = backgroundColors[(norebasePartner).toLowerCase() as keyof typeof backgroundColors]


	return (
		<main>
			<div className='border-b shadow-md'>
				<header className='md:max-w-2xl xl:max-w-3xl mx-auto px-5 py-3 flex items-center justify-between gap-3 '>
					<img src={norebasePartnerLogo} className='w-36 md:w-40'/>
					<span className='font-bold text-2xl'>X</span>
					<img src={norebaseLogo} className='w-36 md:w-40 w-'/>
				</header>
			</div>
			<section className={`text-center py-16 ${backgroundColor} px-5`}>
				<h1 className='text-4xl md:text-5xl text-slate-800 font-bold'>{ titleOne }</h1>
				<h2 className='mt-5 md:mt-6 text-xl md:text-2xl font-semibold text-slate-700'>{ titleTwo }</h2>
				<a target='_blank' href={`https://start.norebase.com/signup?trackingId=${norebasePartner}`}>
					<button className={`${buttonColor} text-white py-4 px-10 rounded-md mt-12`}>
						Get Started
					</button>
				</a>
				<img src={image} className='mx-auto rounded-lg mt-14'/>
			</section>
		</main>
	)
}

export default App;
